import { api } from "@/api";
import store, { ACtx } from "@/store";
import State from "./state";
import { TicketCombination, TicketRequest, CreateTicketState } from "@/types/tickets";
import {
  AUTOPLAY_AFTER_DOUBLING_DELAY,
  MAKE_TICKET_DELAY,
  MAKE_TICKET_DELAY_DIGIT,
  TICKET_COMBINATIONS,
} from "@/constants";
import storeEvents from "@/store-events";
import { AudioUtils } from "@/utils";
import { MakeBonusTicketRequest } from "@/types/bonus";

export const state: CreateTicketState = {
  combination: undefined,
  amount: undefined,
  sumAmount: undefined,
  created: 0,
};

export const mutations = {
  setNewCombination(state: State, value: TicketCombination) {
    state.createTicket.combination = value;
  },
  setNewNominal(state: State, value: number) {
    state.createTicket.amount = value;
  },
  setSumAmount(state: State, value: number) {
    state.createTicket.sumAmount = value;
  },
  setNewCreated(state: State) {
    state.createTicket.created = new Date().getTime() + 100;
  },
};

export const actions = {
  async makeTicket({ state, commit, dispatch }: ACtx) {
    const combination = state.createTicket.combination;
    const amount = state.createTicket.amount;
    const sumAmount = state.createTicket.sumAmount ?? 0;
    const balance = state.userData.balance;
    dispatch("resetErrorStatus");
    dispatch("resetSplit");

    if (state.isGuest) {
      console.warn("guest bet, force register");
      dispatch("register");
      return;
    }

    if (sumAmount > balance) {
      console.warn("not enough money, replenish");
      commit("setMakeTicketDisabled", false);
      store.commit("setAutoGame", false);
      AudioUtils.playSound("stop-autoplay", 0, 1);
      dispatch("toggleModal", "replenish");
      return;
    }

    if (combination && amount) {
      dispatch("closeAllModals");
      dispatch("stopDoublingGame");
      commit("setControlDisabled", true);
      commit("setShowAnimation", "close");
      storeEvents.emit("stopAnimation");
      commit("setLastPayout", 0);
      commit("setMakeTicketDisabled", state.ui.autoGame);

      if (
        state.userData.settings.mevaho_mode === "fruit" &&
        state.currentGameId === "loto-mevaho-5"
      ) {
        AudioUtils.stopSound("slot-carpet");
        AudioUtils.playSound("slot-carpet", 0, 4);
      } else if (
        state.userData.settings.mevaho_mode === "fruit" &&
        state.currentGameId === "loto-funny-fruits"
      ) {
        AudioUtils.stopSound("slot-carpet-mevaho-gold");
        AudioUtils.playSound("slot-carpet-mevaho-gold", 0, 4);
      } else if (
        state.userData.settings.mevaho_mode === "fruit" &&
        state.currentGameId === "loto-gambusaki-9"
      ) {
        AudioUtils.stopSound("slot-carpet-gambusaki");
        AudioUtils.playSound("slot-carpet-gambusaki", 0);
      } else if (
        state.userData.settings.mevaho_mode === "fruit" &&
        state.currentGameId === "loto-gambusaki-gold-9"
      ) {
        AudioUtils.stopSound("slot-carpet-gambusaki-gold");
        AudioUtils.playSound("slot-carpet-gambusaki-gold", 0, 4);
      } else if (
        state.userData.settings.mevaho_mode === "fruit" &&
        state.currentGameId === "loto-khoja"
      ) {
        AudioUtils.stopSound("slot-carpet-khoja");
        AudioUtils.playSound("slot-carpet-khoja", 0, 4);
      } else if (
        state.userData.settings.mevaho_mode === "fruit" &&
        state.currentGameId === "loto-pearl"
      ) {
        AudioUtils.stopSound("slot-carpet-pearl");
        AudioUtils.playSound("slot-carpet-pearl", 0, 4);
      } else if (
        state.userData.settings.mevaho_mode === "fruit" &&
        state.currentGameId === "loto-astrologer"
      ) {
        AudioUtils.stopSound("slot-carpet-astrologer");
        AudioUtils.playSound("slot-carpet-astrologer", 0, 4);
      } else if (
        state.userData.settings.mevaho_mode === "fruit" &&
        state.currentGameId === "loto-kissaho"
      ) {
        AudioUtils.stopSound("slot-carpet-kissaho");
        AudioUtils.playSound("slot-carpet-kissaho", 0, 4);
      } else if (
        state.userData.settings.mevaho_mode === "fruit" &&
        state.currentGameId === "loto-silk-way"
      ) {
        AudioUtils.stopSound("slot-carpet-silk-way");
        AudioUtils.playSound("slot-carpet-silk-way", 0, 4);
      } else if (
        state.userData.settings.mevaho_mode === "fruit" &&
        state.currentGameId === "loto-pamir-legends"
      ) {
        AudioUtils.stopSound("slot-carpet-pamir-legends");
        AudioUtils.playSound("slot-carpet-pamir-legends", 0, 4);
      } else if (
        state.userData.settings.mevaho_mode === "fruit" &&
        state.currentGameId === "loto-misr"
      ) {
        AudioUtils.stopSound("slot-carpet-misr");
        AudioUtils.playSound("slot-carpet-misr", 0, 4);
      }

      try {
        const result = await api.makeTicket({
          combination,
          amount,
        });

        commit("updateTicketId", result);
        window.setTimeout(
          () => {
            commit("resetDigitWinnings");
            storeEvents.emit("createTicket");
          },
          state.userData.settings.mevaho_mode === "digit"
            ? MAKE_TICKET_DELAY_DIGIT
            : MAKE_TICKET_DELAY
        );
        commit("setNewCreated");
      } catch (e: any) {
        console.error("error: ", e);
      }
    }
  },
  async makePromoTicket({ state, commit, dispatch }: ACtx) {
    const combination = state.promo.promo?.combination;
    const amount = state.promo.promo?.amount;
    const promo_id = state.promo.promo?.id;
    const ticketsLeft = state.ui.promoTicketsLeft + 1;
    const sumAmount = state.promo.promo?.combination
      ? Number(state.promo.promo?.combination.replace(/\D/g, "")) * state.promo.promo?.amount
      : 0;
    const balance = state.userData.balance;
    commit("setNewCombination", combination);
    commit("setNewNominal", amount);
    commit("setPromoTicketsLeft", ticketsLeft);
    commit("setSumAmount", sumAmount);
    dispatch("resetErrorStatus");

    if (combination && amount) {
      dispatch("closeAllModals");
      dispatch("stopDoublingGame");
      commit("setControlDisabled", true);
      commit("setShowAnimation", "close");
      storeEvents.emit("stopAnimation");
      commit("setLastPayout", 0);
      commit("setMakeTicketDisabled", state.ui.autoGame);

      if (
        state.userData.settings.mevaho_mode === "fruit" &&
        state.currentGameId === "loto-mevaho-5"
      ) {
        AudioUtils.stopSound("slot-carpet");
        AudioUtils.playSound("slot-carpet", 0, 4);
      } else if (
        state.userData.settings.mevaho_mode === "fruit" &&
        state.currentGameId === "loto-funny-fruits"
      ) {
        AudioUtils.stopSound("slot-carpet-mevaho-gold");
        AudioUtils.playSound("slot-carpet-mevaho-gold", 0, 4);
      } else if (
        state.userData.settings.mevaho_mode === "fruit" &&
        state.currentGameId === "loto-gambusaki-9"
      ) {
        AudioUtils.stopSound("slot-carpet-gambusaki");
        AudioUtils.playSound("slot-carpet-gambusaki", 0);
      } else if (
        state.userData.settings.mevaho_mode === "fruit" &&
        state.currentGameId === "loto-gambusaki-gold-9"
      ) {
        AudioUtils.stopSound("slot-carpet-gambusaki-gold");
        AudioUtils.playSound("slot-carpet-gambusaki-gold", 0, 4);
      } else if (
        state.userData.settings.mevaho_mode === "fruit" &&
        state.currentGameId === "loto-khoja"
      ) {
        AudioUtils.stopSound("slot-carpet-khoja");
        AudioUtils.playSound("slot-carpet-khoja", 0, 4);
      } else if (
        state.userData.settings.mevaho_mode === "fruit" &&
        state.currentGameId === "loto-kissaho"
      ) {
        AudioUtils.stopSound("slot-carpet-kissaho");
        AudioUtils.playSound("slot-carpet-kissaho", 0, 4);
      } else if (
        state.userData.settings.mevaho_mode === "fruit" &&
        state.currentGameId === "loto-pearl"
      ) {
        AudioUtils.stopSound("slot-carpet-pearl");
        AudioUtils.playSound("slot-carpet-pearl", 0, 4);
      } else if (
        state.userData.settings.mevaho_mode === "fruit" &&
        state.currentGameId === "loto-astrologer"
      ) {
        AudioUtils.stopSound("slot-carpet-astrologer");
        AudioUtils.playSound("slot-carpet-astrologer", 0, 4);
      } else if (
        state.userData.settings.mevaho_mode === "fruit" &&
        state.currentGameId === "loto-silk-way"
      ) {
        AudioUtils.stopSound("slot-carpet-silk-way");
        AudioUtils.playSound("slot-carpet-silk-way", 0, 4);
      } else if (
        state.userData.settings.mevaho_mode === "fruit" &&
        state.currentGameId === "loto-pamir-legends"
      ) {
        AudioUtils.stopSound("slot-carpet-pamir-legends");
        AudioUtils.playSound("slot-carpet-pamir-legends", 0, 4);
      } else if (
        state.userData.settings.mevaho_mode === "fruit" &&
        state.currentGameId === "loto-misr"
      ) {
        AudioUtils.stopSound("slot-carpet-misr");
        AudioUtils.playSound("slot-carpet-misr", 0, 4);
      }

      try {
        const result = await api.makeTicket({
          combination,
          amount,
          promo_id,
        });

        commit("updateTicketId", result);
        window.setTimeout(
          () => {
            commit("resetDigitWinnings");
            storeEvents.emit("createTicket");
          },
          state.userData.settings.mevaho_mode === "digit"
            ? MAKE_TICKET_DELAY_DIGIT
            : MAKE_TICKET_DELAY
        );
        commit("setNewCreated");
      } catch (e: any) {
        console.error("error: ", e);
        if (e.code === 16) {
          await dispatch("toggleModal", "promofailed");
        }
      }
    }
  },

  setNewNominal({ state, commit }: ACtx, value: number) {
    const newCombination = store.state.createTicket.combination;
    if (newCombination) {
      const sum = TICKET_COMBINATIONS[newCombination].array.length * value;
      commit("setSumAmount", sum);
    }
    commit("setNewNominal", value);
  },

  setNewCombination({ state, commit }: ACtx, value: TicketCombination) {
    const newAmount = store.state.createTicket.amount;
    if (newAmount) {
      const sum = TICKET_COMBINATIONS[value].array.length * newAmount;
      commit("setSumAmount", sum);
    }
    commit("setNewCombination", value);
  },

  autoMakeTicket(
    { state, commit, dispatch }: ACtx,
    { delay = false, force = false }: { delay: boolean; force: boolean }
  ) {
    const make_ticket_delay = delay ? AUTOPLAY_AFTER_DOUBLING_DELAY : 0;
    if (state.ui.autoGame || force) {
      commit("setMakeTicketDisabled", true);
      window.setTimeout(() => {
        dispatch("makeTicket");
      }, make_ticket_delay);
    }
  },
};

export const getters = {
  newCombination(state: State): TicketCombination | undefined {
    return state.createTicket.combination;
  },
  newCombinationNumber(state: State): number | undefined {
    return state.createTicket.combination
      ? Number(state.createTicket.combination.replace(/\D/g, ""))
      : undefined;
  },
  newNominal(state: State): number | undefined {
    return state.createTicket.amount;
  },
};
